import { Grid } from 'react-loader-spinner';

const LoadingContainer = () => {
	return (
		<div className="flex h-full w-full items-center justify-center">
			<Grid
				visible={true}
				height="50"
				width="50"
				color="#4158D088"
				ariaLabel="grid-loading"
				radius="12.5"
				wrapperStyle={{}}
				wrapperClass="grid-wrapper"
			/>
		</div>
	);
};

export default LoadingContainer;
