import { useEffect } from 'react';
import VConsole from 'vconsole';

if (process.env.REACT_APP_VCONSOLE) {
	new VConsole();
}

import LoadingContainer from '@/components/LoadingContainer';
import { useLiff, useUserInitializer } from '@/util/auth';

type GlobalContainerProps = {
	children: React.ReactNode;
};
const GlobalContainer = ({ children }: GlobalContainerProps) => {
	const { isLoading } = useUserInitializer();
	const { isInitialized, initialize, updateAuthUserByLineProfile } = useLiff();

	useEffect(() => {
		initialize();
	}, []);

	useEffect(() => {
		(async () => {
			if (isInitialized && !isLoading) {
				await updateAuthUserByLineProfile();
			}
		})();
	}, [isInitialized, isLoading]);

	return <>{isLoading || !isInitialized ? <LoadingContainer /> : children}</>;
};

export default GlobalContainer;
