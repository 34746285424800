import liff, { Liff } from "@line/liff";
import { User } from "@supabase/supabase-js";
import { useEffect, useState } from "react";
import useSWR from "swr";

import { supabase } from "@/supabaseClient";

/**
 * supabase にログイン中のユーザを取得する
 */
export const getAuthUser = async () => {
	const {
		data: { user: authUser },
	} = await supabase.auth.getUser();

	return authUser;
};

export const useProfile = () => {
	const fetcher = async () => {
		const authUser = await getAuthUser();
		if (!authUser) {
			return null;
		}
		const { data: profile, error: userError } = await supabase
			.from("profile")
			.select("*")
			.eq("auth_user_id", authUser.id)
			.single();

		return profile;
	};
	const { data: profile, error, isLoading } = useSWR("profile", fetcher);

	return { data: profile, error, isLoading };
};

// supabaseユーザ初期化関数
export const useUserInitializer = () => {
	const [isLoading, setIsLoading] = useState<boolean>(true);

	// ログインのセッションを取得する処理
	useEffect(() => {
		(async () => {
			const {
				data: { session: initSession },
			} = await supabase.auth.getSession();
			let session = initSession;

			let authUser = await getAuthUser();

			if (session && authUser) {
				console.log("already logged in");
			} else {
				console.log("new session is creating...");
				const {
					data: { user: newAuthUser, session: newSession },
					error: signInAnonymouslyError,
				} = await supabase.auth.signInAnonymously();

				if (newAuthUser && newSession) {
					console.log("new logged in");
					session = newSession;
					authUser = newAuthUser;
				} else {
					console.error(
						"signInAnonymously error",
						signInAnonymouslyError,
					);
					return;
				}
			}

			setIsLoading(false);
		})();
	}, []);

	return {
		isLoading,
	};
};

// liff初期化関数
export const useLiff = () => {
	const [isInitialized, setIsInitialized] = useState(false);

	const initialize = (loggedInCallback?: (liff: Liff) => void) => {
		liff
			.init({ liffId: process.env.REACT_APP_LIFF_ID as string })
			.then(() => {
				if (liff.isLoggedIn()) {
					console.log("liff: true");
					loggedInCallback && loggedInCallback(liff);
				} else {
					console.log("liff: false");
					liff.login({
						redirectUri: process.env.REACT_APP_APP_URL as string,
					});
				}
				setIsInitialized(true);
			})
			.catch((error: any) => {
				console.error(`liff.init() failed: ${error}`);
				setIsInitialized(true);
			});
	};

	/**
	 * AuthUser のLINE_IDをアップデートする
	 */
	const updateAuthUserByLineProfile = async () => {
		if (!liff.isLoggedIn()) {
			console.error("liffログインしてください");
			return;
		}

		const idToken = liff.getIDToken();
		if (!idToken) {
			console.error("idTokenが取得できませんでした");
			return;
		}

		const {
			data: { session },
		} = await supabase.auth.getSession();

		await supabase.functions.invoke(
			"update_user_by_line_profile",
			{
				body: JSON.stringify({ id_token: idToken }),
				headers: {
					Authorization: `Bearer ${session?.access_token}`,
				},
			},
		);
	};

	return { isInitialized, initialize, updateAuthUserByLineProfile };
};
