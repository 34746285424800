import * as React from 'react';
import * as ReactHookForm from 'react-hook-form';
import {
	FormControl,
	FormDescription,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from 'src/components/shadcn-ui/form';

import { InputProps, Input as ScInput } from '@/components/shadcn-ui/Input';

type InputPropsType = React.ForwardRefExoticComponent<
	InputProps & React.RefAttributes<HTMLInputElement>
>;

type Props = {
	control: ReactHookForm.Control<any>;
	name: string;
	description?: string;
	placeholder?: string;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	changeTimeout?: number;
	label?: string;
	InputProps?: InputPropsType;
	type?: React.HTMLInputTypeAttribute;
	unit?: string;
	rightContent?: React.ReactNode;
};

const Input = React.forwardRef(
	(
		{
			control,
			name,
			onChange,
			label,
			placeholder,
			changeTimeout,
			type,
			unit,
			rightContent,
			description,
		}: Props,
		ref: React.Ref<any>,
	) => {
		const [timeoutId, setTimeoutId] = React.useState<NodeJS.Timeout | null>(
			null,
		);

		const handleChange = (
			e: React.ChangeEvent<HTMLInputElement>,
			formOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
		) => {
			formOnChange(e);
			if (changeTimeout != null) {
				if (timeoutId != null) {
					clearTimeout(timeoutId);
				}

				// changeTimeoutを設定している場合時間差でonChangeが発火するように
				const newTimeId = setTimeout(() => {
					onChange && onChange(e);
				}, changeTimeout);
				setTimeoutId(newTimeId);
			} else {
				onChange && onChange(e);
			}
		};

		return (
			<FormField
				control={control}
				name={name}
				render={({ field }) => (
					<FormItem className="w-full">
						<FormLabel className="text-base">{label}</FormLabel>
						<FormDescription>{description}</FormDescription>
						<FormControl>
							<div className="flex gap-2 items-center w-full">
								<div className="flex-grow">
									<ScInput
										placeholder={placeholder}
										{...field}
										type={type}
										onChange={(e) => handleChange(e, field.onChange)}
									/>
								</div>
								{/* {unit && <div className="text-sm text-gray-500">{unit}</div>} */}
								{rightContent && (
									<div className="shrink-0">
										{' '}
										{/* rightContentが圧縮されないように設定 */}
										{rightContent}
									</div>
								)}
							</div>
						</FormControl>
						<FormMessage />
					</FormItem>
				)}
			/>
		);
	},
);

export default Input;
