import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import { ScreenWrapper } from '@/components/ScreenWrapper';
import GlobalContainer from '@/containers/GlobalContainer';
import Enquete from '@/pages/Enquete';
import Error from '@/pages/Error';

function App() {
	return (
		<ScreenWrapper>
			<RecoilRoot>
				<GlobalContainer>
					<BrowserRouter>
						<Routes>
							<Route path="/" element={<Enquete />} />
							<Route path="*" element={<Error />} />
						</Routes>
					</BrowserRouter>
				</GlobalContainer>
			</RecoilRoot>
		</ScreenWrapper>
	);
}

export default App;
