type ScreenWrapperProps = {
	children: React.ReactNode;
};
const ScreenWrapper = ({ children }: ScreenWrapperProps) => {
	return (
		<div className="h-screen max-w-md mx-auto bg-white overflow-scroll">
			{children}
		</div>
	);
};

export { ScreenWrapper };
