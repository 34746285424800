import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

/**
 * Date オブジェクトを YYYY/MM/DD 形式の文字列に変換する関数
 * @param date - 変換する Date オブジェクト
 * @returns YYYY/MM/DD 形式の日付文字列
 */
export function formatJaDate(date: Date): string {
	const year = date.getFullYear();
	const month = date.getMonth() + 1; // getMonth() は 0 から始まるため、1 を加える
	const day = date.getDate();

	// 数値を2桁の文字列に変換するために '0' を追加し、最後の2文字を取得
	const monthStr = ('0' + String(month)).slice(-2);
	const dayStr = ('0' + String(day)).slice(-2);

	return `${year}/${monthStr}/${dayStr}`;
}
