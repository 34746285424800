import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import useSWR from 'swr';

import { ScreenBody } from '@/components/ScreenBody';
import { ScreenWrapper } from '@/components/ScreenWrapper';
import Input from '@/components/form/Input';
import { SelectForm } from '@/components/form/SelectForm';
import { Button } from '@/components/shadcn-ui/Button';
import { Form } from '@/components/shadcn-ui/form';
import { useToast } from '@/components/shadcn-ui/use-toast';
import { supabase } from '@/supabaseClient';
import { useProfile } from '@/util/auth';

const defaultValues = {
	weight: '70',
	height: '170',
	exercise_frequency: 'ほとんどしない',
	diet_aim: '健康な体を手に入れたい',
};

const Enquete = () => {
	const { toast } = useToast();
	const form = useForm({ defaultValues });
	const { data: profile } = useProfile();

	const fetcher = async () => {
		if (!profile) return null;

		const { data: existingGoals, error: fetchError } = await supabase
			.from('goal')
			.select('*')
			.eq('profile_id', profile.id);

		return existingGoals?.[0] || null;
	};
	const { data: existingGoal } = useSWR(
		['existingGoals', profile?.id],
		fetcher,
	);

	const handleClickSubmitButton = () => {
		if (!profile) {
			return;
		}
		form.handleSubmit(async (data) => {
			try {
				// セッションの取得
				const {
					data: { session },
				} = await supabase.auth.getSession();
				if (!session) {
					console.error('セッションが見つかりません');
					return;
				}

				// goalテーブルの既存のレコードを確認
				const { data: existingGoals, error: fetchError } = await supabase
					.from('goal')
					.select('*')
					.eq('profile_id', profile.id);

				if (fetchError) {
					console.error('既存のゴールの取得に失敗しました:', fetchError);
					return;
				}

				const goalData = {
					weight: parseFloat(data.weight),
					height: parseFloat(data.height),
					exercise_frequency: data.exercise_frequency,
					diet_aim: data.diet_aim,
					profile_id: profile.id,
				};

				let result;
				if (existingGoals.length > 0) {
					// 既存のレコードを更新
					result = await supabase
						.from('goal')
						.update(goalData)
						.eq('id', existingGoals[0].id);
				} else {
					// 新しいレコードを作成
					result = await supabase.from('goal').insert([goalData]);
				}

				if (result.error) {
					toast({
						title: 'ゴールの保存に失敗しました',
						variant: 'destructive',
					});
				} else {
					toast({
						title: 'ゴールが正常に保存されました',
						variant: 'default',
					});
					// ここで成功メッセージを表示するなどの処理を追加できます
				}
			} catch (error) {
				toast({
					title: 'エラーが発生しました',
					variant: 'destructive',
				});
				console.error('エラーが発生しました:', error);
			}
		})();
	};

	useEffect(() => {
		console.log('existingGoal', existingGoal);
		if (existingGoal) {
			form.reset({
				weight: String(existingGoal.weight) || '',
				height: String(existingGoal.height) || '',
				exercise_frequency: existingGoal.exercise_frequency || '',
				diet_aim: existingGoal.diet_aim || '',
			});
		}
	}, [existingGoal]);

	return (
		<ScreenWrapper>
			<ScreenBody>
				<Form {...form}>
					<div className="mb-4">
						<div className="flex gap-4">
							<div className="flex items-end gap-2">
								<Input
									type="number"
									control={form.control}
									name="weight"
									label="体重"
								/>
								<div className="mb-2">kg</div>
							</div>
							<div className="flex items-end gap-2">
								<Input
									type="number"
									control={form.control}
									name="height"
									label="身長"
								/>
								<div className="mb-2">cm</div>
							</div>
						</div>
						<SelectForm
							control={form.control}
							name="exercise_frequency"
							label="週にどのくらい運動しますか"
							options={[
								{
									label: 'ほとんどしない',
									value: 'ほとんどしない',
								},
								{
									label: '週に1回',
									value: '週に1回',
								},
								{
									label: '週に2~3回',
									value: '週に2~3回',
								},
								{
									label: '週に3~5回',
									value: '週に3~5回',
								},
								{
									label: '毎日',
									value: '毎日',
								},
							]}
						/>
						<SelectForm
							control={form.control}
							name="diet_aim"
							label="ダイエットの目的を選んでください"
							options={[
								{
									label: '健康な体を手に入れたい',
									value: '健康な体を手に入れたい',
								},
								{
									label: '筋肉つけたい',
									value: '筋肉つけたい',
								},
								{
									label: '若々しい体を手に入れたい',
									value: '若々しい体を手に入れたい',
								},
								{
									label: 'ダイエットしたい',
									value: 'ダイエットしたい',
								},
							]}
						/>
					</div>
					<div>
						<Button
							className="w-[100%]"
							variant="default"
							onClick={handleClickSubmitButton}
						>
							記入を完了する
						</Button>
					</div>
				</Form>
			</ScreenBody>
		</ScreenWrapper>
	);
};

export default Enquete;
